import React from "react"
import {graphql, Link} from 'gatsby';
import Layout from '../components/layout';
import Share from '../components/share';
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../hooks/use-site-metadata';

const BookPost = ({ data, location }) => {
    const bookAllPages = data.bookAllPages.nodes
    const { book, bookPost, previous, next } = data

    const { siteUrl, defaultImage } = useSiteMetadata();
    const ogImageUrl = `${siteUrl}${(book.frontmatter.featuredImage && book.frontmatter.featuredImage?.childImageSharp.fluid.src) || defaultImage}`;

    return (
        <Layout>
            <GatsbySeo
                title={bookPost.frontmatter.title}
                description={bookPost.excerpt}
                openGraph={{
                    url: `${siteUrl}${location.pathname}`,
                    title: `${bookPost.frontmatter.title}`,
                    images: [
                        {
                            url: ogImageUrl,
                            width: 1200,
                            height: 630
                        }
                    ]
                }}
            />
            <section id="page-title">

                <div className="container clearfix">
                    <h1>{book.frontmatter.title}</h1>
                    <span>{`${book.frontmatter.author} - ${book.frontmatter.year}`}</span>
                    {/*<ol className="breadcrumb">*/}
                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                    {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
                    {/*</ol>*/}
                </div>

            </section>
            <section id="content">
                <div  className="content-wrap">
                    <div  className="container clearfix">

                        <div  className="row gutter-40 col-mb-80">
                            <div  className="postcontent col-lg-9">

                                <div className="fancy-title title-bottom-border">
                                    <h4>{bookPost.frontmatter.title}</h4>
                                </div>

                                <section
                                    dangerouslySetInnerHTML={{ __html: bookPost.html }}
                                />

                                <div className="clear"></div>

                                <Share socialConfig={{
                                    config: {
                                        url : location.href,
                                        title: bookPost.frontmatter.title
                                    }
                                }} />

                                {(previous || next) && <div className="divider"></div>}

                                <div className="row justify-content-between col-mb-30 post-navigation">
                                    <div className="col-12 col-md-auto text-center">
                                        {previous && (
                                            <Link to={`/${previous.frontmatter.slug}`} rel="prev">
                                                ← {previous.frontmatter.title}
                                            </Link>
                                        )}
                                    </div>

                                    <div className="col-12 col-md-auto text-center">
                                        {next && (
                                            <Link to={`/${next.frontmatter.slug}`} rel="next">
                                                {next.frontmatter.title} →
                                            </Link>
                                        )}
                                    </div>
                                </div>


                            </div>

                            <div  className="sidebar col-lg-3">
                                <div  className="sidebar-widgets-wrap">

                                    {bookAllPages.length > 0 &&
                                    <div className="widget widget_links clearfix">

                                        <h4>İçindekiler</h4>
                                        <ul>
                                            {bookAllPages.map(post => {
                                                return (
                                                    <li key={post.frontmatter.slug}><Link activeClassName="current" to={`/${post.frontmatter.slug}`}>{post.frontmatter.title}</Link></li>
                                                )
                                            })}
                                        </ul>

                                    </div>
                                    }
                                    <div className="widget clearfix">
                                        <h4>{book.frontmatter.title}</h4>
                                        <img src={book.frontmatter.featuredImage?.childImageSharp.fluid.src} style={{maxHeight: '300px', borderRadius: '3px'}} alt={`${book.frontmatter.title} - ${book.frontmatter.author}`}/>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default BookPost

export const pageQuery = graphql`
  query bookQuery (
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $filterRegex: String
    $bookRegex: String
  ) {
    bookPost: markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    
    previous: markdownRemark(id: { eq: $previousPostId }) {
      frontmatter {
        title
        slug
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      frontmatter {
        title
        slug
      }
    }
    bookAllPages: allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: $filterRegex  }}
        sort: { fields: [frontmatter___no], order: ASC }
        ) {
      nodes {
        excerpt
        frontmatter {
          title
          slug
          no
        }
      }
    }
    
    book: markdownRemark(
       fileAbsolutePath: {regex: $bookRegex }
    ) {
      id
      frontmatter {
        title
        year
        author
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
        
        
        
  }
`
